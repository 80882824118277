import Inicio from "../components/Inicio";

function Home() {
  return (
    
      <div>
        <Inicio/>
      </div>
  );
}

export default Home;