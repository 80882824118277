import Main from "../components/Main";

function Criptos() {
  return (
    
      <div>
        <Main/>
      </div>
  );
}

export default Criptos;